import axios from "axios";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router";


const cookies = new Cookies();

const werkenbij = axios.create({
  baseURL: 'https://werkenbijtechdog.nl/v1/api',
});

// Request interceptor to add the ApiKey header
werkenbij.interceptors.request.use(config => {
  const token = cookies.get('token');
  if (token) {
    config.headers.ApiKey = token; // Adds the header to the request if it exists.
  }
  return config;
}, error => {
  // Do something with request error
  return Promise.reject(error);
});

werkenbij.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response && error.response.status === 401) {
    // Dispatch a custom event
    window.dispatchEvent(new CustomEvent('unauthorized-event'));
  }
  return Promise.reject(error);
});

export default werkenbij;