import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar/Navbar';
import './Template.css';
import '../MainStyling/Main.css';

const PageTemplate = ({children}) => {
  return (
    <div className="d-flex flex-nowrap w-100">
      <div className="d-flex flex-column w-100">
        <Navbar />
        <div>
          <div className='contentBox'>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageTemplate;