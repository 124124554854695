import React, { useEffect, useState } from 'react';
import PageLayout from '../PageLayout/PageLayout';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import "./Settings.css";
import Cookies from 'universal-cookie';
import werkenbij from '../../Api/werkenbij';
import { useNavigate } from 'react-router';
import ToastSuccess from '../../Toasts/ToastSuccess';
import ToastError from '../../Toasts/ToastError';
import eyeOpen from '../../Images/eye.svg';
import eyeClosed from '../../Images/eye-closed.svg';
import { removeLoginCookies } from '../Login/Login';

const Settings = () => {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [loading, setLoading] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [newDepartment, setNewDepartment] = useState("");
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [showOldPass, setShowOldPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);

  const fetchAll = async () => {
    try {
      setLoading(true);
      const [fetchDepartments] = await Promise.all([
        werkenbij.get("/info/departments"),
        werkenbij.get("/sp/allvacancies"),
      ]);

      setDepartments(fetchDepartments.data);
      setLoading(false);
    } catch (error) {
      
      if (error.response && error.response.status === 401) {
        removeLoginCookies()
      }
    }
  };

  useEffect(() => {
    fetchAll();
  }, []);

  const handleAddDepartment = async (event) => {
    if (event) {
      event.preventDefault();
    }
    console.log(newDepartment.trim());

    if (newDepartment.trim()) {
      try {
        const resp = await werkenbij.post(`info/departments`, {
          title: newDepartment,
        });
        setDepartments([...departments, resp.data]);
        setNewDepartment("");
      } catch (error) {
        console.log(error);
        ToastError(`${error.response.data.Error}`);
      }
    }
  };

  const handleRemoveDepartment = async (department_id) => {
    try {
      const resd = await werkenbij.delete(`info/departments`, {
        data: { department_id: department_id },
      });
      if (resd.status === 200) {
        setDepartments(departments.filter(department => department.department_id !== department_id));
      }
    } catch (error) {
      ToastError("kan werkgebied niet verwijderen omdat er nog vacatures zijn met dit werkgebied");

    }
  };

  const passwordChange = async (event) => {
    if (event) {
      event.preventDefault();
    }
    try {
      const resPass = await werkenbij.post(`changepassword`, {
        old_password: oldPass,
        new_password: newPass
      });

      ToastSuccess(resPass.data.message);
    } catch (error) {
      const message = error.response.data.message;
      let errorMessage = '';
      
      const requirements = `
        Het wachtwoord moet minimaal bevatten:
        Eén hoofdletter | Eén kleine letter | Eén nummer | Eén speciaal teken
      `;
      
      if (message.includes('new')) {
          errorMessage = `${requirements}`;
      } else {
          errorMessage = message;
      }
      
      ToastError(errorMessage);
    }

    setOldPass('');
    setNewPass('');
  }

  if (loading) {
    return <LoadingScreen>laden</LoadingScreen>;
  }

  return (
    <PageLayout>
      <div style={{ width: "60%" }}>
        <div>
          <h3>Wachtwoord Wijzigen</h3>
          <form className='formSettings' onSubmit={passwordChange}>
            <div className='d-flex flex-column mb-4'>
              <label className='fw-semibold mb-2'>Oud wachtwoord</label>
              <div className='input-container'>
                <input 
                  type={showOldPass ? 'text' : 'password'} 
                  className='inputstyle w-100' 
                  value={oldPass} 
                  onChange={(e) => setOldPass(e.target.value)} 
                  required 
                />
                <span 
                  className='toggle-visibility d-flex align-items-center' 
                  onClick={() => setShowOldPass(!showOldPass)}
                >
                  {showOldPass ? <img src={eyeOpen} style={{width:"20px", height:"20px"}} alt='open'/> : <img src={eyeClosed} alt='closed' style={{width:"20px", height:"20px"}} />}
                </span>
              </div>
            </div>
            <div className='d-flex flex-column mb-4'>
              <label className='fw-semibold mb-2'>Nieuw wachtwoord</label>
              <div className='input-container'>
                <input 
                  type={showNewPass ? 'text' : 'password'} 
                  className='inputstyle w-100' 
                  value={newPass} 
                  onChange={(e) => setNewPass(e.target.value)} 
                  required 
                />
                <span 
                  className='toggle-visibility d-flex align-items-center' 
                  onClick={() => setShowNewPass(!showNewPass)}
                >
                  {showNewPass ? <img src={eyeOpen} style={{width:"20px", height:"20px"}} alt='open'/> : <img src={eyeClosed} alt='closed' style={{width:"20px", height:"20px"}} />}
                </span>
              </div>
            </div>
            <button className='settingsButtons w-100'>Wachtwoord wijzigen</button>
          </form>
        </div>
        <div className='pt-5'>
          <h3 className='mb-3'>Beheer werkgebieden</h3>
          <form onSubmit={handleAddDepartment} className='d-flex justify-content-between flex-row'>
            <input
              className='inputstyle'
              style={{ width: "75%" }}
              placeholder='Nieuw werkgebied'
              value={newDepartment}
              onChange={(e) => setNewDepartment(e.target.value)}
            />
            <button type='submit' className='settingsButtons' style={{ width: "23%" }}>Toevoegen</button>
          </form>
          <div className='d-flex flex-row flex-wrap'>
            {departments.map((department) => (
              <div key={department.department_id} className="smallLabels m-2">
                {department.title}
                <button className='buttonSmallLabels' onClick={() => handleRemoveDepartment(department.department_id)}>x</button>
              </div>
            ))}
          </div>
        </div>
        <div className='pt-5 mb-5'>
          <button onClick={() => removeLoginCookies(navigate('/login'))} className='settingsButtons w-100'>Log uit</button>
        </div>
      </div>
    </PageLayout>
  );
}

export default Settings;
