import React, { useEffect, useState, useRef } from 'react';
import PageLayout from '../../PageLayout/PageLayout';
import "./VacanciesAdd.css";
import { useNavigate } from "react-router";
import Cookies from "universal-cookie";
import werkenbij from '../../../Api/werkenbij';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import Loadingscreen from "../../../LoadingScreen/LoadingScreen";
import ToastError from '../../../Toasts/ToastError';
import ToastSuccess from '../../../Toasts/ToastSuccess';
import refreshIcon from '../../../Images/reload.svg'

const VacanciesAdd = () => {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [departments, setDepartments] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    functionName: '',
    departmentId: '',
    jobTypeId: '',
    workHours: '',
    base64VacancyHeader: null,
    base64UrJob: null,
    base64WorkdayAs: null,
    base64CoffeeWith: null,
    base64Case: null,
    offerInputs: [{ id: 1, value: '' }],  // Initialize with one input
    discoverMoreInputs: [{ id: 1, value: '' }],  // Initialize with one input
    jobPercentage: [{ id: 1, percentage: '', description: '' }],  // Initialize with one input
    colleagueNames: [{ id: 1, name: '', description: '' }],  // Initialize with one input
    casePoints: [{ id: 1, value: '' }],  // Initialize with one input
    funcDescription: '',
    jobDescriptionText: '',
    coffeeWith: '',
    clientName: ''
  });
  const [errors, setErrors] = useState({});
  const [inputIdCounter, setInputIdCounter] = useState(2); // Start from 2 because 1 is already used
  const [charCounts, setCharCounts] = useState({
    funcDescription: 0,
    jobDescriptionText: 0,
    colleagueDescriptions: {}, // To handle multiple colleague descriptions
  });
  const MAX_CHAR_LIMIT = 250;
  const MAX_CHAR_LIMIT_DEFAULT = 101;
  const MAX_CHAR_LIMIT_FUNCTION = 130; 
  const MAX_CHAR_LIMIT_URJOB = 500; 

  const functionNameRef = useRef(null);
  const departmentRef = useRef(null);
  const jobTypeRef = useRef(null);
  const workHoursRef = useRef(null);
  const funcDescriptionRef = useRef(null);
  const coffeeWithRef = useRef(null);
  const clientNameRef = useRef(null);
  
  // Initialize dynamic input refs
  const offerInputsRef = useRef([]);
  const discoverMoreInputsRef = useRef([]);
  const jobPercentageRef = useRef([]);
  const colleagueNamesRef = useRef([]);
  const casePointsRef = useRef([]);

  const fetchAll = async () => {
    try {
      setLoading(true);
      const [fetchDepartments, fetchJobTypes] = await Promise.all([
        werkenbij.get("/info/departments"),
        werkenbij.get("/info/jobtypes"),
      ]);
      setDepartments(fetchDepartments.data);
      setJobTypes(fetchJobTypes.data);
      setLoading(false);
    } catch (error) {
      if (error.response.status === 401) {
        cookies.remove("token", { path: "/" });
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    fetchAll();
  }, []);

  const scrollToFirstError = () => {
    const allRefs = [
      functionNameRef.current,
      departmentRef.current,
      jobTypeRef.current,
      workHoursRef.current,
      funcDescriptionRef.current,
      coffeeWithRef.current,
      clientNameRef.current,
      ...offerInputsRef.current,
      ...discoverMoreInputsRef.current,
      ...jobPercentageRef.current,
      ...colleagueNamesRef.current,
      ...casePointsRef.current
    ];

    for (const ref of allRefs) {
      if (ref && ref.classList && ref.classList.contains("is-invalid")) {
        ref.scrollIntoView({ behavior: "smooth", block: "center" });
        return true;
      }
    }
    return false;
  };

  const getTotalPercentage = (jobPercentage = formData.jobPercentage) => {
    return jobPercentage.reduce((total, item) => total + (parseInt(item.percentage) || 0), 0);
  };

  const hasEmptyInputs = (inputs, field, refArray) => {
    const shouldCheck = inputs.length > 1 || inputs.some(input => {
        return (input.value || input.name || input.percentage || input.description);
    });

    if (!shouldCheck) return false;

    const hasEmpty = inputs.some((input, index) => {
        let isEmpty = false;
        if (input.value !== undefined && input.value.trim() === '') {
            setErrors(prevErrors => ({
                ...prevErrors,
                [field]: { ...prevErrors[field], [input.id]: { value: true } }
            }));
            ToastError("Er mogen geen lege velden zijn bij de velden met het plusje");
            refArray.current[index]?.classList.add('is-invalid');
            isEmpty = true;
        } else if (input.name !== undefined && input.description !== undefined && 
                   (input.name.trim() === '' || input.description.trim() === '')) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [field]: { 
                    ...prevErrors[field], 
                    [input.id]: { 
                        name: input.name.trim() === '', 
                        description: input.description.trim() === '' 
                    } 
                }
            }));
            if (input.name.trim() === '') {
                ToastError("Er mogen geen lege velden zijn bij naam van 'Onze collega's aan het woord'");
                refArray.current[index]?.classList.add('is-invalid');
            } else {
                ToastError("Er mogen geen lege velden zijn bij description van 'Onze collega's aan het woord'");
                refArray.current[index + inputs.length]?.classList.add('is-invalid');
            }
            isEmpty = true;
        } else if (input.percentage !== undefined && input.description !== undefined && 
                  (!input.percentage || input.description.trim() === '')) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [field]: { 
                    ...prevErrors[field], 
                    [input.id]: { 
                        percentage: !input.percentage, 
                        description: input.description.trim() === '' 
                    } 
                }
            }));
            if (!input.percentage) {
                ToastError("Er mogen geen lege velden zijn bij procent van 'zo kan jouw functie er uit zien'");
                refArray.current[index]?.classList.add('is-invalid');
            } else {
                ToastError("Er mogen geen lege velden zijn bij description van 'zo kan jouw functie er uit zien'");
                refArray.current[index + inputs.length]?.classList.add('is-invalid');
            }
            isEmpty = true;
        } else {
            setErrors(prevErrors => ({
                ...prevErrors,
                [field]: { 
                    ...prevErrors[field], 
                    [input.id]: { 
                        value: false, 
                        name: false, 
                        percentage: false, 
                        description: false 
                    } 
                }
            }));
            refArray.current[index]?.classList.remove('is-invalid');
            refArray.current[index + inputs.length]?.classList.remove('is-invalid');
        }
        return isEmpty;
    });
    return hasEmpty;
};

  const fileToBase64 = (file, callback) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64 = reader.result;
      callback(base64, file.name); // Stuur ook de bestandsnaam mee
    };
    reader.onerror = (error) => console.log('Error: ', error);
  };
  const handleImageFileChange = (event, fieldName) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const validImageTypes = [
        'image/jpeg',
        'image/png',
        'image/jpg',
        'image/svg+xml',
        'image/webp',
      ];
  
      if (!validImageTypes.includes(file.type)) {
        ToastError("Alleen afbeeldingsbestanden (PNG, JPEG, SVG, WEBP) zijn toegestaan");
        return;
      }
  
      if (file.size <= 5000000) {
        fileToBase64(file, (base64, fileName) => {
          setFormData(prevState => ({
            ...prevState,
            [`base64${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}`]: base64,
            [`fileName${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}`]: fileName,
          }));
        });
      } else {
        ToastError("De afbeelding is te groot, maximaal 5MB");
      }
    }
  };
  
  const handleVideoFileChange = (event, fieldName) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const validVideoTypes = [
        'video/mp4',
      ];
  
      if (!validVideoTypes.includes(file.type)) {
        ToastError("Alleen videobestanden (MP4) zijn toegestaan");
        return;
      }
  
      if (file.size <= 30000000) {
        fileToBase64(file, (base64, fileName) => {
          setFormData(prevState => ({
            ...prevState,
            [`base64${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}`]: base64,
            [`fileName${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}`]: fileName,
          }));
        });
      } else {
        ToastError("De video is te groot, maximaal 30MB");
      }
    }
  };

  const handleAddInput = (field, max = 6) => {
    if (formData[field].length < max) {
      setFormData(prevState => ({
        ...prevState,
        [field]: [...prevState[field], { id: inputIdCounter, value: '' }],
      }));
      setInputIdCounter(prevCounter => prevCounter + 1);
    } else if (formData[field].length == max) {
      ToastError(`Mag niet meer dan ${max} velden toevoegen`)
    }
  };

  const handleAddJobPercentageInput = () => {
    if (formData.jobPercentage.length < 4 && getTotalPercentage() < 100) {
      setFormData(prevState => ({
        ...prevState,
        jobPercentage: [...prevState.jobPercentage, { id: inputIdCounter, percentage: '', description: '' }],
      }));
      setInputIdCounter(prevCounter => prevCounter + 1);
    } else if (formData.jobPercentage.length == 4) {
      ToastError("Mag niet meer dan 4 velden toevoegen");
    } else {
      ToastError("Het totaal aantal procenten kan niet meer dan 100% zijn");
    }
  };

  const handleAddColleagueInput = () => {
    if (formData.colleagueNames.length < 3) {
      setFormData(prevState => ({
        ...prevState,
        colleagueNames: [...prevState.colleagueNames, { id: inputIdCounter, name: '', description: '' }],
      }));
      setInputIdCounter(prevCounter => prevCounter + 1);
    } else if (formData.colleagueNames.length == 3) {
      ToastError("Mag er niet meer dan 3 velden toevoegen")
    }
  };

  const handleRemoveInput = (id, field) => {
    if (formData[field].length > 1) {
      setFormData(prevState => ({
        ...prevState,
        [field]: prevState[field].filter(input => input.id !== id),
      }));
    }
    setErrors((prevState) => {
      const newErrors = { ...prevState };
      if (newErrors[field] && newErrors[field][id]) {
        delete newErrors[field][id];
        // Clean up the field if it has no more errors
        if (Object.keys(newErrors[field]).length === 0) {
          delete newErrors[field];
        }
      }
      return newErrors;
    });
  };

  const handleRemoveColleagueInput = (id) => {
    if (formData.colleagueNames.length > 1) {
      setFormData(prevState => ({
        ...prevState,
        colleagueNames: prevState.colleagueNames.filter(input => input.id !== id),
      }));
    }

    setErrors((prevState) => {
      const newErrors = { ...prevState };
      if (newErrors.colleagueNames && newErrors.colleagueNames[id]) {
        delete newErrors.colleagueNames[id];
        // Clean up the field if it has no more errors
        if (Object.keys(newErrors.colleagueNames).length === 0) {
          delete newErrors.colleagueNames;
        }
      }
      return newErrors;
    });
  };

  const handleInputChange = (id, value, field, inputField = "value") => {
    if (field === "jobPercentage" && inputField === "percentage") {
      if (parseInt(value) > 100) {
        ToastError("percentage mag niet hoger zijn dan 100%");
        return;
      } else if (parseInt(value) < 1) {
        ToastError("percentage mag niet lager zijn dan 1%");
        return;
      }

      const newJobPercentage = formData.jobPercentage.map((item) =>
        item.id === id ? { ...item, percentage: value } : item
      );

      if (getTotalPercentage(newJobPercentage) > 100) {
        ToastError("Het totaal aantal procenten kan niet meer dan 100% zijn");
        return;
      }
    }

    if (value.length <= MAX_CHAR_LIMIT_DEFAULT) {
      setFormData((prevState) => ({
        ...prevState,
        [field]: prevState[field].map((input) =>
          input.id === id ? { ...input, [inputField]: value } : input
        ),
      }));

      if (
        (field === "offerInputs" ||
          field === "discoverMoreInputs" ||
          field === "jobPercentage" ||
          field === "casePoints") &&
        (value.trim() === "" || value.length > MAX_CHAR_LIMIT_DEFAULT - 1)
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: { ...prevErrors[field], [id]: { [inputField]: true } },
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: { ...prevErrors[field], [id]: { [inputField]: false } },
        }));
      }
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: { ...prevErrors[field], [id]: { [inputField]: true } },
      }));
    }
  };

  const handleColleagueInputChange = (id, value, inputField) => {
    const maxCharLimit =
      inputField === "description" ? MAX_CHAR_LIMIT : MAX_CHAR_LIMIT_DEFAULT;

    if (value.length <= maxCharLimit) {
      if (inputField === "description") {
        setCharCounts((prevCounts) => ({
          ...prevCounts,
          colleagueDescriptions: {
            ...prevCounts.colleagueDescriptions,
            [id]: value.length,
          },
        }));
      }
      setFormData((prevState) => ({
        ...prevState,
        colleagueNames: prevState.colleagueNames.map((input) =>
          input.id === id ? { ...input, [inputField]: value } : input
        ),
      }));

      if (
        inputField === "name" &&
        (value.trim() === "" || value.length > MAX_CHAR_LIMIT_DEFAULT - 1)
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          colleagueNames: {
            ...prevErrors.colleagueNames,
            [id]: { [inputField]: true },
          },
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          colleagueNames: {
            ...prevErrors.colleagueNames,
            [id]: { [inputField]: false },
          },
        }));
      }
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        colleagueNames: {
          ...prevErrors.colleagueNames,
          [id]: { [inputField]: true },
        },
      }));
    }
  };
  
  const handleInputChangeGeneral = (field, value) => {
    let maxCharLimit;

    switch (field) {
      case "funcDescription":
        maxCharLimit = MAX_CHAR_LIMIT_FUNCTION;
        break;
      case "jobDescriptionText":
        maxCharLimit = MAX_CHAR_LIMIT_URJOB;
        break;
      case "functionName":
        maxCharLimit = 51;
        break;
      default:
        maxCharLimit = MAX_CHAR_LIMIT_DEFAULT;
    }

    if (value.length <= maxCharLimit) {
      setCharCounts((prevCounts) => ({
        ...prevCounts,
        [field]: value.length,
      }));
      setFormData((prevState) => ({
        ...prevState,
        [field]: value,
      }));

      if ( field === "functionName" && (value.trim() === "" || value.length > maxCharLimit - 1)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: true,
        }));
      } else if (( field === "coffeeWith"  || field === "clientName") && (value.length > maxCharLimit - 1) ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: true,
        }));
      } else if (field === "workHours" && (value > 40 || value <= -1 || value.trim() === "")) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: true,
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: false,
        }));
      }
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: true,
      }));
    }
  };

  const validateForm = (form) => {
    if (form.checkValidity() === false) {
      return false;
    }
    return true;
  };

  const validateField = (field, value) => {
    if (field === 'functionName') {
      console.log(value.trim() === '');

      return value.trim() === '' || value.length > 50;
    }
    if (field === 'departmentId' || field === 'jobTypeId' || field === 'workHours' || field === 'funcDescription') {
      return value.trim() === '';
    }
    return false;
  };


  const getAllDepartments = async () => {
    try {
      setLoading(true);
      const [fetchDepartments] = await Promise.all([
        werkenbij.get("/info/departments"),
      ]);
      setDepartments(fetchDepartments.data);
      setLoading(false);
    } catch (error) {
      if (error.response.status === 401) {
        cookies.remove("token", { path: "/" });
        navigate("/login");
      }
    }
  };

  const submitNewVacancy = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (scrollToFirstError()) {
      setLoading(false);
      return;
    }

    const form = event.currentTarget;
  
    let errorsFound = {};
    let hasError = false;
  
    // Validate required fields
    const requiredFields = [
      "functionName",
      "departmentId",
      "jobTypeId",
      "workHours",
      "funcDescription",
    ];
    
    requiredFields.forEach((field) => {
      if (validateField(field, formData[field])) {
        errorsFound[field] = true;
        hasError = true;
      }
    });
  
    // Check for errors in dynamic fields
    const dynamicFields = [
      "offerInputs",
      "discoverMoreInputs",
      "jobPercentage",
      "colleagueNames",
      "casePoints",
    ];
  
    dynamicFields.forEach((field) => {
      if (errors[field]) {
        Object.values(errors[field]).forEach((fieldErrors) => {
          if (Object.values(fieldErrors).includes(true)) {
            hasError = true;
          }
        });
      }
    });

    const otherFields = [
      "coffeeWith",
      "clientName",
    ]
        
    otherFields.forEach((field) => {
      if (errors[field]) {
        errorsFound[field] = true;
        hasError = true;
      }
    });


  
    if (hasError) {
      setErrors(errorsFound)
      setTimeout(() => {
        scrollToFirstError();
      }, 50)
      setLoading(false);
      return; // Stop the function execution if there are errors
    }

    try {
        let jobOfferId = 0;

        try {
            const jobOfferPayload = {
                function_name: formData.functionName,
                department_id: parseInt(formData.departmentId),
                job_type_id: parseInt(formData.jobTypeId),
                work_hours: parseInt(formData.workHours),
                function_description: formData.funcDescription,
                job_description: formData.jobDescriptionText || "",
                employee: formData.coffeeWith || "",
                case_name: formData.clientName || "",
                ...(formData.base64VacancyHeader && { job_offer_header_image: formData.base64VacancyHeader }),
                ...(formData.base64UrJob && { job_description_image: formData.base64UrJob }),
                ...(formData.base64CoffeeWith && { employee_image: formData.base64CoffeeWith }),
                ...(formData.base64Case && { case_upload: formData.base64Case }),
            };

            const formDataPayload = new FormData();
            formDataPayload.append('function_name', jobOfferPayload.function_name);
            formDataPayload.append('department_id', jobOfferPayload.department_id);
            formDataPayload.append('job_type_id', jobOfferPayload.job_type_id);
            formDataPayload.append('work_hours', jobOfferPayload.work_hours);
            formDataPayload.append('function_description', jobOfferPayload.function_description);
            formDataPayload.append('job_description', jobOfferPayload.job_description);
            formDataPayload.append('employee', jobOfferPayload.employee);
            formDataPayload.append('case_name', jobOfferPayload.case_name);

            if (formData.base64VacancyHeader) {
                formDataPayload.append('job_offer_header_image', formData.base64VacancyHeader);
            }

            if (formData.base64UrJob) {
                formDataPayload.append('job_description_image', formData.base64UrJob);
            }

            if (formData.base64CoffeeWith) {
                formDataPayload.append('employee_image', formData.base64CoffeeWith);
            }

            if (formData.base64Case) {
                formDataPayload.append('case_upload', formData.base64Case);
            }

            const res = await werkenbij.post("joboffers", formDataPayload, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            jobOfferId = res.data.job_offer_id;

            if (formData.base64WorkdayAs) {
                await werkenbij.post(`joboffersworkdayvideos`, {
                    job_offer_id: jobOfferId,
                    ...(formData.base64WorkdayAs && { workday_video_path: formData.base64WorkdayAs }),
                });
            }

        } catch (err) {
            console.error("Error bij het aanmaken van de vacature:", err);
            setLoading(false)
            throw err;
        }

        const promises = [];
        const errors = [];

        for (const weOffer of formData.offerInputs) {
            if (weOffer.value.trim() !== '') {
                promises.push(
                    (async () => {
                        try {
                            await werkenbij.post("weoffer", {
                                title: weOffer.value || "",
                                job_offer_id: jobOfferId,
                            });
                        } catch (err) {
                            errors.push("Error posting we offer: " + err.message);
                            console.error("Error posting we offer:", err);
                        }
                    })()
                );
            }
        }

        for (const discover of formData.discoverMoreInputs) {
            if (discover.value.trim() !== '') {
                promises.push(
                    (async () => {
                        try {
                            await werkenbij.post("exploremore", {
                                title: discover.value || "",
                                job_offer_id: jobOfferId,
                            });
                        } catch (err) {
                            errors.push("Error posting discover more: " + err.message);
                            console.error("Error posting discover more:", err);
                        }
                    })()
                );
            }
        }

        for (const job of formData.jobPercentage) {
            if (job.percentage.trim() !== '' && job.description.trim() !== '') {
                promises.push(
                    (async () => {
                        try {
                            await werkenbij.post("jobdistribution", {
                                percentage: parseInt(job.percentage),
                                title: job.description || "",
                                job_offer_id: jobOfferId || 0,
                            });
                        } catch (err) {
                            errors.push("Error posting job distribution: " + err.message);
                            console.error("Error posting job distribution:", err);
                        }
                    })()
                );
            }
        }

        for (const cases of formData.casePoints) {
            if (cases.value.trim() !== '') {
                promises.push(
                    (async () => {
                        try {
                            await werkenbij.post("casepoints", {
                                title: cases.value || "",
                                job_offer_id: jobOfferId,
                            });
                        } catch (err) {
                            errors.push("Error posting case points: " + err.message);
                            console.error("Error posting case points:", err);
                        }
                    })()
                );
            }
        }

        for (const colleague of formData.colleagueNames) {
            if (colleague.name.trim() !== '' && colleague.description.trim() !== '') {
                promises.push(
                    (async () => {
                        try {
                            await werkenbij.post("employeeexperiences", {
                                full_name: colleague.name || "",
                                experience_description: colleague.description || "",
                                job_offer_id: jobOfferId,
                            });
                        } catch (err) {
                            errors.push("Error posting colleague experience: " + err.message);
                            console.error("Error posting colleague experience:", err);
                        }
                    })()
                );
            }
        }

        await Promise.all(promises);

        if (errors.length > 0) {
            ToastError(errors.join("\n"));
            setLoading(false)
        } else {
          ToastSuccess("Vacature succesvol toegevoegd")
          window.location.reload();
        }

    } catch (err) {
        console.error("Error met het aanmaken van de vacature:", err);
        setLoading(false)
    }
  };

  console.log(errors);

  if (loading) {
    return <Loadingscreen>laden</Loadingscreen>;
  }

  return (
    <PageLayout>
      <div className='w-50 d-flex flex-column'>
        <h3 className='fw-bold mb-3'>Vacature toevoegen</h3>
        <form onSubmit={submitNewVacancy}>
          <div className='mb-5'>
            <h4 className='fw-bold mb-3'>Algemeen</h4>
            <div className="mb-3">
              <label
                className="mb-2 fw-semibold"
                htmlFor="validationFunctionName"
              >
                Functienaam*
              </label>
              <input
                type="text"
                className={`form-control inputstyle ${
                  errors.functionName ? "is-invalid" : ""
                }`}
                id="validationFunctionName"
                value={formData.functionName}
                onChange={(e) =>
                  handleInputChangeGeneral("functionName", e.target.value)
                }
                onPaste={(e) => {
                  e.preventDefault();
                  const paste = (
                    e.clipboardData || window.clipboardData
                  ).getData("text");
                  handleInputChangeGeneral(
                    "functionName",
                    formData.functionName +
                      paste.substring(0, 50 - formData.functionName.length)
                  );
                }}
                ref={functionNameRef}
              />
              <div className="invalid-feedback">
                Functienaam is verplicht en mag niet langer dan 50 tekens zijn.
              </div>
            </div>
            <div className='mb-3'>
              <div className='d-flex flex-row justify-content-between'>
                <label className='mb-2 fw-semibold' htmlFor='validationDepartment'>Werkgebied*</label>
                <div onClick={() => getAllDepartments()}>
                  <img src={refreshIcon} style={{width:"20px", height:"20px"}}/>
                </div>
              </div>
              <select 
                className={`form-select inputstyle ${errors.departmentId ? 'is-invalid' : ''}`} 
                id='validationDepartment' 
                value={formData.departmentId} 
                onChange={(e) => handleInputChangeGeneral('departmentId', e.target.value)} 
                ref={departmentRef}
              >
                <option value="" disabled hidden>Selecteer een werkgebied</option>
                {departments.map((department, key) => (
                  <option key={key} value={department.department_id}>{department.title}</option>
                ))}
              </select>
              <div className="invalid-feedback">Werkgebied is verplicht.</div>
            </div>
            <div className='mb-3'>
              <label className='mb-2 fw-semibold' htmlFor='validationJobType'>Type baan*</label>
              <select 
                className={`form-select inputstyle ${errors.jobTypeId ? 'is-invalid' : ''}`} 
                id='validationJobType' 
                value={formData.jobTypeId} 
                onChange={(e) => handleInputChangeGeneral('jobTypeId', e.target.value)} 
                ref={jobTypeRef}
              >
                <option value="" disabled hidden>Selecteer een type baan</option>
                {jobTypes.map((jobType, key) => (
                  <option key={key} value={jobType.job_type_id}>{jobType.title}</option>
                ))}
              </select>
              <div className="invalid-feedback">Type baan is verplicht.</div>
            </div>
            <div className='mb-3'>
              <label className='mb-2 fw-semibold' htmlFor='validationWorkHours'>Uren*</label>
              <input 
                type='number' 
                className={`form-control inputstyle ${errors.workHours ? 'is-invalid' : ''}`} 
                id='validationWorkHours' 
                max={40} 
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                value={formData.workHours} 
                onChange={(e) => handleInputChangeGeneral('workHours', e.target.value)} 
                ref={workHoursRef}
              />
              <div className="invalid-feedback">Uren moeten tussen 0 en 40 liggen.</div>
            </div>
            <div className='mb-3 col-md-12 position-relative'>
              <label className='mb-2 fw-semibold' htmlFor='validationFuncDescription'>Functie omschrijving*</label>
              <textarea  
                rows={6} 
                cols={5} 
                className={`form-control p-3 inputstyle ${errors.funcDescription ? 'is-invalid' : ''}`} 
                id='validationFuncDescription' 
                value={formData.funcDescription} 
                onChange={(e) => handleInputChangeGeneral('funcDescription', e.target.value)} 
                maxLength={MAX_CHAR_LIMIT_FUNCTION}
                ref={funcDescriptionRef}
              />
              <div className="invalid-feedback">Functie omschrijving is verplicht.</div>
              <div className='char-counter position-absolute top-0 end-0 p-2'>
                {charCounts.funcDescription}/{MAX_CHAR_LIMIT_FUNCTION}
              </div>
            </div>
          </div>
          <div>
            <h4 className='fw-bold mb-3'>Informatie vacaturepagina</h4>
            <div className='mb-3'>
              <label className='mb-2 fw-semibold'>Upload vacature header</label>
              <div className="file-upload">
                <input
                  type="file"
                  id="actual-btn-header"
                  hidden
                  accept="image/*"
                  onChange={(e) => handleImageFileChange(e, 'vacancyHeader')}
                />
                <label htmlFor="actual-btn-header" className="file-label">{formData.fileNameVacancyHeader || "Kies bestand"}</label>
                <label htmlFor="actual-btn-header" className="file-chosen">Uploaden</label>
              </div>
            </div>
            <div className='mb-3'>
              <div className='mb-2 d-flex justify-content-between'>
                <label className='fw-semibold'>Dit bieden wij <small style={{ color: "#707070" }} className='ms-3'>(maximaal 6 toevoegen)</small></label>
                <label className='btn-icon' onClick={() => handleAddInput('offerInputs')}><FontAwesomeIcon icon={faPlus} /></label>
              </div>
              {formData.offerInputs.map((input, index) => (
                <div key={input.id} className='d-flex align-items-center mb-2 '>
                  <div className='d-flex flex-column w-100'>
                    <input
                      ref={el => offerInputsRef.current[index] = el}
                      type="text"
                      className={`inputstyle flex-grow-1 ${errors.offerInputs?.[input.id]?.value ? 'is-invalid' : ''}`}
                      value={input.value}
                      onChange={(e) => handleInputChange(input.id, e.target.value, 'offerInputs')}
                    />
                    <div className="invalid-feedback">Dit veld mag niet langer dan 100 tekens zijn.</div>
                  </div>

                  {formData.offerInputs.length > 1 && (
                    <label className='btn-icon ms-2' onClick={() => handleRemoveInput(input.id, 'offerInputs')}><FontAwesomeIcon icon={faMinus} /></label>
                  )}
                </div>
              ))}
            </div>
            <div className='mb-3'>
              <div className='mb-2 d-flex justify-content-between'>
                <label className='fw-semibold'>Ontdek meer over</label>
                <label className='btn-icon' onClick={() => handleAddInput('discoverMoreInputs')}><FontAwesomeIcon icon={faPlus} /></label>
              </div>
              {formData.discoverMoreInputs.map((input, index) => (
                <div key={input.id} className='d-flex align-items-center mb-2'>
                  <div className='d-flex flex-column w-100'>
                    <input
                      ref={el => discoverMoreInputsRef.current[index] = el}
                      type="text"
                      className={`inputstyle flex-grow-1 ${errors.discoverMoreInputs?.[input.id]?.value ? 'is-invalid' : ''}`}
                      value={input.value}
                      onChange={(e) => handleInputChange(input.id, e.target.value, 'discoverMoreInputs')}
                    />
                    <div className="invalid-feedback">Dit veld mag niet langer dan 100 tekens zijn.</div>
                  </div>
                  {formData.discoverMoreInputs.length > 1 && (
                    <label className='btn-icon ms-2' onClick={() => handleRemoveInput(input.id, 'discoverMoreInputs')}><FontAwesomeIcon icon={faMinus} /></label>
                  )}
                </div>
              ))}
            </div>
            <div className='blueStripeSeperator my-4' />
            <div className='mb-3 position-relative'>
              <label className='mb-2 fw-semibold'>Dit is de baan</label>
              <textarea 
                rows={6} 
                cols={5} 
                className='inputstyle p-3' 
                value={formData.jobDescriptionText} 
                onChange={(e) => handleInputChangeGeneral('jobDescriptionText', e.target.value)} 
                maxLength={MAX_CHAR_LIMIT_URJOB}
              />
              <div className='char-counter position-absolute top-0 end-0 p-2'>
                {charCounts.jobDescriptionText}/{MAX_CHAR_LIMIT_URJOB}
              </div>
            </div>
            <div className='mb-3'>
              <label className='mb-2 fw-semibold'>Upload "Dit is de baan" photo</label>
              <div className="file-upload">
                <input
                  type="file"
                  id="actual-btn-job"
                  hidden
                  accept="image/*"
                  onChange={(e) => handleImageFileChange(e, 'urJob')}
                />
                <label htmlFor="actual-btn-job" className="file-label">{formData.fileNameUrJob || "Kies bestand"}</label>
                <label htmlFor="actual-btn-job" className="file-chosen">Uploaden</label>
              </div>
            </div>
            <div className='blueStripeSeperator my-4' />
            <div className='mb-3'>
              <div className='mb-2 d-flex justify-content-between'>
                <label className='fw-semibold'>Zo kan jouw functie er uit zien <small style={{ color: "#707070" }} className='ms-3 fw-semibold'>(maximaal 4 toevoegen)</small></label>
                <label className='btn-icon' onClick={handleAddJobPercentageInput}><FontAwesomeIcon icon={faPlus} /></label>
              </div>
              {formData.jobPercentage.map((input, index) => (
                <div key={input.id} className='d-flex align-items-center mb-2'>
                    <input
                      ref={el => jobPercentageRef.current[index] = el}
                      type='number'
                      className={`inputstyle w-25 me-3 ${errors.jobPercentage?.[input.id]?.percentage ? 'is-invalid' : ''}`}
                      placeholder='Percentage'
                      max={100}
                      min={0}
                      value={input.percentage}
                      onChange={(e) => handleInputChange(input.id, e.target.value, 'jobPercentage', 'percentage')}
                    />
                    <div className='d-flex flex-column w-100'>
                      <input
                        ref={el => jobPercentageRef.current[index + formData.jobPercentage.length] = el}
                        type="text"
                        className={`inputstyle flex-grow-2 ${errors.jobPercentage?.[input.id]?.description ? 'is-invalid' : ''}`}
                        placeholder='Korte beschrijving'
                        value={input.description}
                        onChange={(e) => handleInputChange(input.id, e.target.value, 'jobPercentage', 'description')}
                      />
                      <div className="invalid-feedback">Dit veld mag niet langer dan 100 tekens zijn.</div>
                    </div>
                  {formData.jobPercentage.length > 1 && (
                    <label className='btn-icon ms-2' onClick={() => handleRemoveInput(input.id, 'jobPercentage')}><FontAwesomeIcon icon={faMinus} /></label>
                  )}
                </div>
              ))}
            </div>
            <div className='blueStripeSeperator my-4' />
            <div className='mb-3'>
              <label className='mb-2 fw-semibold'>Upload "Een werkdag als" video</label>
              <div className="file-upload">
                <input
                  type="file"
                  id="actual-btn-workday"
                  hidden
                  accept="video/*"
                  onChange={(e) => handleVideoFileChange(e, 'workdayAs', "video")}
                />
                <label htmlFor="actual-btn-workday" className="file-label">{formData.fileNameWorkdayAs || "Kies bestand"}</label>
                <label htmlFor="actual-btn-workday" className="file-chosen">Uploaden</label>
              </div>
            </div>
            <div className='blueStripeSeperator my-4' />
            <div className='mb-3'>
              <label className='mb-2 fw-semibold'>Ik doe graag een kop koffie met</label>
                  <div className='d-flex flex-column w-100'>
                    <input
                      type="text"
                      className={`inputstyle ${errors.coffeeWith ? 'is-invalid' : ''}`}
                      value={formData.coffeeWith || ''}
                      placeholder='[Voor- en achternaam invullen]'
                      onChange={(e) => handleInputChangeGeneral('coffeeWith', e.target.value)}
                      onPaste={(e) => {
                        e.preventDefault();
                        const paste = (
                          e.clipboardData || window.clipboardData
                        ).getData("text");
                        handleInputChangeGeneral(
                          "coffeeWith",
                          formData.coffeeWith +
                            paste.substring(0, 100 - formData.coffeeWith.length)
                        );
                      }}
                      ref={coffeeWithRef}
                    />
                    <div className="invalid-feedback">Dit veld mag niet langer dan 100 tekens zijn.</div>
                  </div>
            </div>
            <div className='mb-3'>
              <label className='mb-2 fw-semibold'>Upload "Ik doe graag een kop koffie met" foto</label>
              <div className="file-upload">
                <input
                  type="file"
                  id="actual-btn-coffee"
                  hidden
                  accept="image/*"
                  onChange={(e) => handleImageFileChange(e, 'coffeeWith', "image")}
                />
                <label htmlFor="actual-btn-coffee" className="file-label">{formData.fileNameCoffeeWith || "Kies bestand"}</label>
                <label htmlFor="actual-btn-coffee" className="file-chosen">Uploaden</label>
              </div>
            </div>
            <div className='blueStripeSeperator my-4' />
            <div className='d-flex flex-row justify-content-between mb-3'>
              <div className='fw-bold fs-6'>Onze collega's aan het woord <small style={{ color: "#707070" }} className='ms-3'>(maximaal 3 toevoegen)</small></div>
              <label className='btn-icon' onClick={handleAddColleagueInput}><FontAwesomeIcon icon={faPlus} /></label>
            </div>
            <div className='mb-3'>
              {formData.colleagueNames.map((input, index) => (
                <div key={input.id} className='d-flex flex-column mb-2'>
                  <div className='d-flex flex-row justify-content-between'>
                    <label className='fw-semibold mb-2'>Voor- en achternaam collega</label>
                    {formData.colleagueNames.length > 1 && (
                      <label className='btn-icon ms-2' onClick={() => handleRemoveColleagueInput(input.id)}><FontAwesomeIcon icon={faMinus} /></label>
                    )}
                  </div>

                  <div className='d-flex align-items-center mb-2'>
                    <div className='d-flex flex-column w-100'>
                      <input
                        ref={el => colleagueNamesRef.current[index] = el}
                        type="text"
                        className={`inputstyle flex-grow-1 mb-3 ${errors.colleagueNames?.[input.id]?.name ? 'is-invalid' : ''}`}
                        value={input.name}
                        onChange={(e) => handleColleagueInputChange(input.id, e.target.value, 'name')}
                      />
                      <div className="invalid-feedback">Naam collega is verplicht en mag niet langer dan 100 tekens zijn.</div>
                    </div>
                  </div>

                  <label className='mb-2 fw-semibold'>Text collega</label>
                  <div className='position-relative'>
                    <textarea
                      ref={el => colleagueNamesRef.current[index + formData.colleagueNames.length] = el}
                      rows={4}
                      className={`inputstyle mb-4 p-3 ${errors.colleagueNames?.[input.id]?.description ? 'is-invalid' : ''}`}
                      value={input.description}
                      onChange={(e) => handleColleagueInputChange(input.id, e.target.value, 'description')}
                    />
                    <div className="invalid-feedback">Beschrijving collega is verplicht en mag niet langer dan 250 tekens zijn.</div>
                    <div className='char-counter position-absolute top-0 end-0 p-2'>
                      {charCounts.colleagueDescriptions[input.id] || 0}/{MAX_CHAR_LIMIT}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className='blueStripeSeperator mb-4' />
            <div className='mb-3'>
              <label className='mb-2 fw-semibold'>Naam opdrachtgever</label>
              <input 
                type="text" 
                className={`form-control inputstyle ${errors.clientName ? 'is-invalid' : ''}`} 
                value={formData.clientName || ""} 
                onChange={(e) => handleInputChangeGeneral('clientName', e.target.value)} 
                onPaste={(e) => {
                  e.preventDefault();
                  const paste = (
                    e.clipboardData || window.clipboardData
                  ).getData("text");
                  handleInputChangeGeneral(
                    "clientName",
                    formData.clientName +
                      paste.substring(0, 100 - formData.clientName.length)
                  );
                }}
                ref={clientNameRef}
              />
              <div className="invalid-feedback">Dit veld mag niet langer dan 100 tekens zijn.</div>
            </div>
            <div className='mb-3'>
              <div className='mb-2 d-flex justify-content-between'>
                <label className='fw-semibold'>Case points <small style={{ color: "#707070" }} className='ms-3 fw-semibold'>(maximaal 3 toevoegen)</small></label>
                <label className='btn-icon' onClick={() => handleAddInput('casePoints', 3)}><FontAwesomeIcon icon={faPlus} /></label>
              </div>
              {formData.casePoints.map((input, index) => (
                <div key={input.id} className='d-flex align-items-center mb-2'>
                  <div className='d-flex flex-column w-100'>
                    <input
                      ref={el => casePointsRef.current[index] = el}
                      type="text"
                      className={`inputstyle flex-grow-1 ${errors.casePoints?.[input.id]?.value ? 'is-invalid' : ''}`}
                      value={input.value}
                      onChange={(e) => handleInputChange(input.id, e.target.value, 'casePoints')}
                    />
                    <div className="invalid-feedback">Dit veld mag niet langer dan 100 tekens zijn en mag ook niet leeg zijn</div>
                  </div>
                  {formData.casePoints.length > 1 && (
                    <label className='btn-icon ms-2' onClick={() => handleRemoveInput(input.id, 'casePoints')}><FontAwesomeIcon icon={faMinus} /></label>
                  )}
                </div>
              ))}
            </div>
            <div className='mb-3'>
              <label className='mb-2 fw-semibold'>Upload case photo</label>
              <div className="file-upload">
                <input
                  type="file"
                  id="actual-btn-case"
                  hidden
                  accept="image/*"
                  onChange={(e) => handleImageFileChange(e, 'case', "image")}
                />
                <label htmlFor="actual-btn-case" className="file-label">{formData.fileNameCase || "Kies bestand"}</label>
                <label htmlFor="actual-btn-case" className="file-chosen">Uploaden</label>
              </div>
            </div>
          </div>
          <button type="submit" className='submitButtonVacancieAdd my-4 fw-semibold'>Toevoegen</button>
        </form>
      </div>
    </PageLayout>
  );
}

export default VacanciesAdd;
