import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import Cookies from 'universal-cookie';
import './LoadingScreen.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { removeLoginCookies } from '../BackofficeWerkenbij/Login/Login';

const LoadingScreen = () => {

    const [showButton, setShowButton] = useState(false);
    const [logOut, setLogOut] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
      const timer = setTimeout(() => {
        setShowButton(true);
        setLogOut(true);
      }, 5000);
  
      return () => clearTimeout(timer);
    }, []);


    return (
        <div className="parent position-absolute" >
            <div className="d-flex align-items-center position-relative align-middle flex-column" style={{top: '20vh'}}>
                <div className="spinner-border text-white mb-3" role="status" style={{ height: '100px', width: '100px'}}/>
                <h3 style={{maxWidth: 'fit-content'}} className=" text-white mb-5">Laden...</h3>
                {showButton && (
                    <div className='d-flex flex-column text-white text-center'>
                        <h3 className='mb-4'>Het kan even duren</h3>
                        <button onClick={e => window.location.reload() } className='text-white link bg-transparent border-0 mb-2 rounded'>Probeer opnieuw <FontAwesomeIcon icon={faRotateRight}/></button>                        
                    </div>
                )}
                {logOut && (
                    <button onClick={() => removeLoginCookies(navigate('/login'))} className='text-white link bg-transparent border-0 rounded'>Log uit</button>
                )}
            </div>
        </div>
    );
}

export default LoadingScreen;
