import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Cookies from 'universal-cookie';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../../Images/logo tech dog_group_met payoff.png';
import "./Login.css"
import eyeOpen from '../../Images/eye.svg';
import eyeClosed from '../../Images/eye-closed.svg';
import werkenbij from '../../Api/werkenbij';

export const removeLoginCookies = (navigate = null) => {
  const cookies = new Cookies();
  cookies.remove('token', { path: '/' });
  
  if (navigate) {
    navigate(`/login`);
  }
};

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");

  const cookies = new Cookies();
  const navigate = useNavigate();

  const expirationDate = new Date();
  expirationDate.setHours(23, 59, 59, 0); // Set the time to 23:59:59.000

  useEffect(() => {
    (async () => {
      const key = cookies.get('token');
      if (key) {
        try {
          await werkenbij.post(`auth`, {
            ApiKey: key,
          });
          navigate('/backoffice/vacature');
        } catch (error) {
          if (error.response.status !== 401) {
            console.warn(error.response.data);
          }
          removeLoginCookies();
        }
      }
    })()
  }, []);
  
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await werkenbij.post(`auth`, {
        email: email,
        password: password,
      });
      cookies.set('token', response.data.api_token, { path: '/', expires: expirationDate });
      navigate('/backoffice/vacature');
    } catch (error) {
      setError(error.response.data.error);
    }
  }
  
  return (
    <div className='backgroundGradient'>
      <div className='d-flex justify-content-center h-100'>
        <form onSubmit={handleLogin} className='d-flex align-items-center flex-column justify-content-center'>
          <div className=''>
            <img src={logo} className="logoLogin" alt="techdoglogo"/>
          </div>
          <div className="mb-4 input-group-sm">
            <input type="email" name='email' required onChange={e => setEmail(e.target.value)} className="form-control inputsUserCredentials" placeholder="Email"/>
          </div>
          <div className="mb-4 input-group-sm">
            <div className='input-containerLogin'>
              <input 
                type={showPassword ? 'text' : 'password'} 
                name='password' 
                required 
                onChange={e => setPassword(e.target.value)} 
                className="form-control inputsUserCredentials w-100" 
                placeholder="Wachtwoord"
              />
              <span 
                className='toggle-visibilityLogin d-flex align-items-center' 
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <img src={eyeOpen} style={{width:"20px", height:"20px"}} alt='open'/> : <img src={eyeClosed} alt='closed' style={{width:"20px", height:"20px"}} />}
              </span>
            </div>
          </div>
          <div className='mb-3 input-group-sm w-100'>
            <button type="submit" className="btn w-100 rounded-pill loginButton">Inloggen</button>
          </div>
          <span className='text-danger fw-bolder fs-5 text-wrap text-center'>{error}</span>
        </form>
      </div>
    </div>
  );
}

export default Login;
