import React from 'react';
import "./ComponentsStyling.css";

const IconButton = ({ sideIcon, icon, textButton, reverted, action, mail, phone, disabled}) => {
  const hrefValue = mail ? `mailto:${mail}` : phone ? `tel:${phone}` : null;

  const buttonContent = (
    <>
      {sideIcon === "left" ? (
        <>
          <div className={`me-3 ${reverted ? "iconbackgroundReverted" : "iconbackground"}`}>
            <img src={icon} className='d-flex align-self-center iconButton' alt="icon" />
          </div>
          <span className='mb-0 text-white d-flex align-self-center me-3 fs-6 text-nowrap'>
            {textButton}
          </span>
        </>
      ) : sideIcon === "right" ? (
        <>
          <div className={`ms-3 ${reverted ? "iconbackgroundReverted" : "iconbackground"}`}>
            <img src={icon} className='d-flex align-self-center iconButton' alt="icon" />
          </div>
          <span className='mb-0 text-white d-flex align-self-center ms-3 fs-6 text-nowrap'>
            {textButton}
          </span>
        </>
      ) : ""}
    </>
  );

  return hrefValue ? (
    <a href={hrefValue} disabled={disabled} className={`d-flex ${sideIcon === "left" ? "flex-row" : "flex-row-reverse"} ${reverted ? "IconButtonStyleReverted" : "IconButtonStyle"}`}>
      {buttonContent}
    </a>
  ) : (
    <button className={`d-flex ${sideIcon === "left" ? "flex-row" : "flex-row-reverse"} ${reverted ? "IconButtonStyleReverted" : "IconButtonStyle"}`} disabled={disabled} onClick={action}>
      {buttonContent}
    </button>
  );
};

export default IconButton;
