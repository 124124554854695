import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import "./PageLayout.css";
import logo from "../../Images/logo tech dog_group_met payoff.png";
import '../../MainStyling/Main.css';

const PageLayout = ({children}) => {
  const location = useLocation();
  
  // Determine the active button based on the current URL
  const getActiveClass = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  return (
    <div className='PageLayout'>
      <div className='py-5 d-flex flex-column align-items-center ParentNavigationbar'>
        <img className='mb-5 logoBackoffice' src={logo} alt="Logo" />
        <div className='d-flex flex-row justify-content-between w-100'>
          <Link to="../backoffice/vacature" className={`BackofficeButtonsNavigation ${getActiveClass('/backoffice/vacature')}`}>
            Vacatures
          </Link>
          <Link to="../backoffice/vacature_nieuw" className={`BackofficeButtonsNavigation ${getActiveClass('/backoffice/vacature_nieuw')}`}>
            Vacatures toevoegen
          </Link>
          <Link to="../backoffice/instellingen" disabled className={`BackofficeButtonsNavigation ${getActiveClass('/backoffice/instellingen')}`}>
            Instellingen
          </Link>
        </div>
      </div>
      <div className='w-100 d-flex align-items-center flex-column'>
        {children}
      </div>
    </div>
  );
}

export default PageLayout;
