import React from 'react';
import suitcase from "../Images/briefcase.svg";
import clock from "../Images/clock.svg";
import arrowRight from "../Images/Icon ionic-ios-arrow-round-forward.png";
import logoTechdog from "../Images/BannerTechdog.jpg";
import IconButton from './IconButton';
import { useNavigate } from "react-router";


const VacanciesCard = ({ img, functionName, hours, department, description, navVacancie }) => {
  const navigate = useNavigate();

  const truncateDescription = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  
  return (
    <div className="cardContainer">
      <div className="imgWrapper">
        <img className='imgCard' src={img || logoTechdog} alt={truncateDescription(functionName, 30)} />
      </div>
      <div className='LabelInfo'>
        <div className='funcTitle h-50 px-3 text-nowrap overflow-hidden'>{truncateDescription(functionName, 27)}</div>
        <div className='funcInfo h-50 px-3 d-flex justify-content-center flex-column'>
          <div className='mb-0 d-flex flex-row align-items-center'><img src={suitcase} className='me-3' style={{height: "20px"}}/>{department ? department : "Onbekend"}</div>
          <div className='mb-0 d-flex flex-row align-items-center'><img src={clock} style={{height: "16px", paddingLeft: "2px", marginRight: "18px"}}/>{hours ? hours : "Onbekend"} uur</div>
        </div>
      </div>
      <div className='jobDescription px-3 pb-4'>
        <p className='functiondescription'>{truncateDescription(description, 150)}</p>
        <div className='iconButtonArrowRight'>
          <IconButton sideIcon={"right"} icon={arrowRight} textButton={"Bekijk vacature"} action={() => navigate(`/vacatureInfo/${navVacancie}`)} />
        </div>
      </div>
    </div>
  );
}

export default VacanciesCard;
